/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "ibsoftcv/Navbars/DefaultNavbar";
import DefaultFooter from "ibsoftcv/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/Software/sections/information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/polygonal-3d.jpg";
import { List, ListItem } from "@mui/material";

function Sysint() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container style={{ marginTop: 90 }}>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Integração de Sistemas: Unificando Tecnologia e Procedimentos
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
              style={{ textAlign: "justify" }}
            >
              Em uma era digital onde a informação é o melhor patrimônio que uma empresa pode
              possuir, a integração de sistemas tornou-se um fator crítico para o sucesso
              empresarial. Com o aumento da complexidade e a necessidade de gerenciar grandes
              volumes de dados, as empresas buscam formas eficazes de unificar seus sistemas e
              processos, maximizando a eficiência e reduzindo os erros.
              <br />A integração de sistemas é o processo de combinar múltiplos aplicativos e
              tecnologias para criar uma plataforma única e integrada. Isso permite que as empresas
              compartilhem informações entre diferentes departamentos e áreas, melhorando a
              comunicação e a colaboração. Com a integração de sistemas, você pode:
              <List sx={{ listStyleType: "disc", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  Reduzir erros e duplicidades de dados
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Melhorar a eficiência operacional e reduzir custos
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Fortalecer as relações com os clientes e parceiros
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Fornecer experiências de usuário mais personalizadas e atraentes
                </ListItem>
              </List>
              <br />
              Nossa empresa especializa-se em integração de sistemas, oferecendo soluções
              personalizadas e eficazes para ajudar você a atingir seus objetivos empresariais. Com
              nossa expertise em tecnologia e nossos recursos humanos altamente qualificados,
              podemos ajudá-lo a unificar seus sistemas e processos, garantindo que sua empresa
              esteja pronta para o futuro. <br />
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              href="/contactus"
            >
              Contatar
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Conete Conosco
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.facebook.com/ilhasbusinesssoftware"
                mr={3}
              >
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
        <Featuring />
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Sysint;
