// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();
const FreePick = () => {
  return <img src="https://fps.cdnpk.net/favicons/favicon-96x96.png" width="24px" />;
};
export default {
  brand: {
    name: "Ilhas Business Software",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/ilhasbusinessoftware/",
    },
    {
      icon: <TwitterIcon />,
      link: "#",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/ibsoftcv",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },
    {
      icon: <FreePick />,
      link: "https://img.freepik.com/free-vector/abstract-geometric-turtle_23-2147522280.jpg?size=626&ext=jpg&ga=GA1.2.1613077493.1717671276&semt=ais_user",
    },
  ],
  menus: [
    {
      name: "Empresa",
      items: [
        { name: "Sobre IBS", href: "/pages/landing-pages/about-us" },
        { name: "História", href: "#" },
        { name: "Equipe", href: "#" },
        { name: "Valores e Missão", href: "#" },
      ],
    },
    {
      name: "Serviços",
      items: [
        { name: "Software", href: "/pages/landing-pages/software" },
        { name: "Consultoria", href: "pages/landing-pages/constech" },
        { name: "Sistemas", href: "pages/landing-pages/sysint" },
      ],
    },
    {
      name: "Clientes e Parceiros",
      items: [
        { name: "Clientes Atuais", href: "#" },
        { name: "Depoimentos", href: "#" },
      ],
    },
    {
      name: "Privacidade",
      items: [
        { name: "Termos & condições", href: "/terms" },
        { name: "Confidencialidade", href: "/privacy" },
        { name: "Política GDPR", href: "/cookies" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos direitos reservados Copyright &copy; {date} IBS{"-"}
      <MKTypography
        component="a"
        href="/presentation"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Ilhas Business Software
      </MKTypography>
    </MKTypography>
  ),
};
