/*
=========================================================
* Material Kit 2 React - v2<br/>1<br/>0
=========================================================

* Product Page: https://www<br/>creative-tim<br/>com/product/material-kit-react
* Copyright 2023 Ilhas Business Software (https://www<br/>creative-tim<br/>com)

Coded by www<br/>creative-tim<br/>com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software<br/>
*/

// Material Kit 2 React components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "ibsoftcv/Navbars/DefaultNavbar";
import DefaultFooter from "ibsoftcv/Footers/DefaultFooter";
import Breadcrumbs from "ibsoftcv/Breadcrumbs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
// Images
import bgImage from "assets/images/city-profile.jpg";

function TermsOfUse() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Container>
          <Breadcrumbs
            xs={12}
            lg={9}
            justifyContent="center"
            mx="auto"
            routes={[
              { label: "Termos & condições", route: "/terms" },
              { label: "Política GDPR", route: "/cookies" },
              { label: "Confidencialidade", route: "/privacy" },
            ]}
          />
          <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mt={-35}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["14pt"],
                },
              })}
            >
              Termos e Serviços
            </MKTypography>
            <MKTypography
              variant="body1"
              color="lightgray"
              px={{ xs: 1, lg: 8 }}
              textAlign="justify"
              mt={1}
            >
              <MKTypography
                variant="h2"
                color="black"
                mt={3}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Termos Gerais
              </MKTypography>{" "}
              Última atualização: <br />
              06 de junho de 2024
              <br />
              <br />
              Ao acessar e fazer um pedido com a Ilhas Business Software, você confirma que está de
              acordo e vinculado aos termos e condições contidos nos Termos de Uso descritos abaixo
              <br />
              Esses termos se aplicam a todo o site e a qualquer e-mail ou outro tipo de comunicação
              entre você e a Ilhas Business Software
              <br /> Em nenhuma circunstância a equipe da Ilhas Business Software será responsável
              por quaisquer danos diretos, indiretos, especiais, incidentais ou consequenciais,
              incluindo, mas não se limitando, à perda de dados ou lucros, decorrentes do uso ou da
              incapacidade de usar os seus produtos, mesmo que a equipe da Ilhas Business Software
              ou um representante autorizado tenha sido avisado da possibilidade de tais danos
              <br /> Se o uso de algum produto da Ilhas Business Software resultar na necessidade de
              serviços, reparos ou correções de equipamentos ou dados, você assumirá quaisquer
              custos decorrentes
              <br /> A Ilhas Business Software não será responsável por qualquer resultado que possa
              ocorrer durante o uso dos nossos recursos
              <br /> Reservamo-nos o direito de alterar os preços e revisar a política de uso dos
              recursos a qualquer momento
              <br />{" "}
              <MKTypography
                variant="h2"
                color="black"
                mt={1}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Produtos
              </MKTypography>{" "}
              Todos os produtos e serviços são entregues pela Ilhas Business Software
              <br /> Você pode acessar seu download a partir do seu painel de controle
              <br />{" "}
              <MKTypography
                variant="h2"
                color="black"
                mt={1}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Segurança
              </MKTypography>{" "}
              A Ilhas Business Software não processa nenhum pagamento de pedidos através do site
              <br />
              Todos os pagamentos são processados de forma segura através do 2Checkout ou Vinti4 que
              são provedores de pagamento online de terceiros
              <br />
              <MKTypography
                variant="h2"
                color="black"
                mt={1}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Política de Cookies
              </MKTypography>{" "}
              Um cookie é um arquivo contendo um identificador (uma sequência de letras e números)
              que é enviado por um servidor web a um navegador web e é armazenado pelo navegador
              <br /> O identificador é então enviado de volta ao servidor cada vez que o navegador
              solicita uma página do servidor
              <br /> Nosso site usa cookies
              <br /> Ao usar nosso site e concordar com esta política, você consente com o uso de
              cookies de acordo com os termos desta política
              <br /> Usamos cookies de sessão para personalizar o site para cada usuário
              <br /> Usamos cookies persistentes para acompanhar as referências vindas de nossa rede
              de afiliados
              <br /> Usamos o Google Analytics para analisar o uso do nosso site
              <br /> Nosso provedor de serviços de análise gera informações estatísticas e outras
              sobre o uso do site por meio de cookies
              <br /> A política de privacidade do nosso provedor de serviços de análise está
              disponível em: http://www.google.com/policies/privacy/
              <br /> Excluir cookies terá um impacto negativo na usabilidade do site
              <br /> Se você bloquear cookies, não poderá usar todos os recursos do nosso site
              <br />
              <MKTypography
                variant="h2"
                color="black"
                mt={1}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Termos e Serviços
              </MKTypography>{" "}
              Processamos todos os nossos pagamentos através do Vinti4 e do 2Checkout
              <br /> O 2Checkout está totalmente em conformidade com os regulamentos do OFAC,
              restringindo compradores dos seguintes países de fazer pedidos:
              <br />
              · A República de Cuba
              <br />
              · A República Islâmica do Irã <br />· O Estado da Líbia <br />· A República do Sudão
              <br />
              · A República Árabe da Síria
              <br />
              · A República Popular Democrática da Coreia
              <br />
              <br /> A lista de países pode ser atualizada sem aviso prévio a qualquer momento pelo
              OFAC e será aplicada imediatamente
              <br /> Verifique o site oficial do OFAC para as últimas atualizações
              <br />
              <MKTypography
                variant="h2"
                color="black"
                mt={1}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Reembolsos
              </MKTypography>{" "}
              Você tem 24 horas para inspecionar sua compra e determinar se ela não atende às
              expectativas estabelecidas pelo vendedor
              <br /> No caso de você desejar receber um reembolso, a Ilhas Business Software emitirá
              um reembolso e solicitará que você especifique como o produto não atendeu às
              expectativas
              <br /> Propriedade A propriedade do produto é regida pela licença de uso selecionada
              pelo vendedor
              <br /> Alterações nos Termos Se alterarmos nossos termos de uso, publicaremos essas
              alterações nesta página
              <br /> Os usuários registrados receberão um e-mail que descreve as alterações feitas
              nos nossos termos de uso.
              <br />
            </MKTypography>
          </Grid>
        </Container>
        <Divider pt={6} mt={6} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default TermsOfUse;
