/*
=========================================================
* Material Kit 2 React - v2<br/>1<br/>0
=========================================================

* Product Page: https://www<br/>creative-tim<br/>com/product/material-kit-react
* Copyright 2023 Ilhas Business Software (https://www<br/>creative-tim<br/>com)

Coded by www<br/>creative-tim<br/>com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software<br/>
*/

// Material Kit 2 React components
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Material Kit 2 React examples
import DefaultNavbar from "ibsoftcv/Navbars/DefaultNavbar";
import DefaultFooter from "ibsoftcv/Footers/DefaultFooter";
import Breadcrumbs from "ibsoftcv/Breadcrumbs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function GDPRPolicy() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(rgba(gradients.light.main, 0.8), rgba(gradients.dark.state, 0.8))}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Container>
          <Breadcrumbs
            xs={12}
            lg={9}
            justifyContent="center"
            mx="auto"
            routes={[
              { label: "Termos & condições", route: "/terms" },
              { label: "Política GDPR", route: "/cookies" },
              { label: "Confidencialidade", route: "/privacy" },
            ]}
          />
          <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mt={-35}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["14pt"],
                },
              })}
            >
              Termos e Serviços
            </MKTypography>
            <MKTypography
              variant="body1"
              color="lightgray"
              px={{ xs: 1, lg: 8 }}
              textAlign="justify"
              mt={1}
            >
              <MKTypography
                variant="h2"
                color="black"
                mt={3}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Política de Privacidade
              </MKTypography>{" "}
              <TableContainer>
                <TableRow>
                  <TableCell>
                    <h1>Política de Cookies para Ilhas Business Software</h1>

                    <h2>Introdução</h2>
                    <p>
                      Na Ilhas Business Software, usamos cookies e tecnologias semelhantes para
                      melhorar a experiência do usuário em nosso site, analisar o tráfego e
                      personalizar o conteúdo. Esta política de cookies explica o que são cookies,
                      como os usamos e como você pode gerenciá-los.
                    </p>

                    <h2>O que são Cookies?</h2>
                    <p>
                      Cookies são pequenos arquivos de texto armazenados no seu dispositivo
                      (computador, smartphone, tablet) quando você visita um site. Eles permitem que
                      o site reconheça o seu dispositivo e lembre-se de certas informações sobre sua
                      visita.
                    </p>

                    <h2>Tipos de Cookies que Utilizamos</h2>

                    <h3>Cookies Essenciais</h3>
                    <p>
                      Esses cookies são necessários para o funcionamento do nosso site. Eles
                      permitem que você navegue pelo site e use recursos essenciais, como áreas
                      seguras e carrinhos de compras.
                    </p>

                    <h3>Cookies de Desempenho</h3>
                    <p>
                      Esses cookies coletam informações sobre como os visitantes usam nosso site,
                      como as páginas que são visitadas com mais frequência. Usamos essas
                      informações para melhorar o funcionamento do site.
                    </p>

                    <h3>Cookies de Funcionalidade</h3>
                    <p>
                      Esses cookies permitem que nosso site se lembre das escolhas que você faz
                      (como seu nome de usuário, idioma ou região) e forneça recursos aprimorados e
                      mais pessoais.
                    </p>

                    <h3>Cookies de Publicidade</h3>
                    <p>
                      Esses cookies são usados para entregar anúncios que são mais relevantes para
                      você e seus interesses. Eles também são usados para limitar o número de vezes
                      que você vê um anúncio e ajudar a medir a eficácia das campanhas
                      publicitárias.
                    </p>

                    <h3>Cookies de Terceiros</h3>
                    <p>
                      Em alguns casos, usamos cookies de terceiros que são definidos por serviços
                      diferentes dos nossos. Por exemplo, usamos o Google Analytics para entender
                      como nossos usuários interagem com o site.
                    </p>

                    <h2>Gerenciamento de Cookies</h2>
                    <p>
                      Você pode gerenciar ou desativar os cookies diretamente nas configurações do
                      seu navegador. Abaixo estão links para instruções sobre como fazer isso nos
                      navegadores mais populares:
                    </p>
                    <List>
                      <ListItem>
                        <a
                          href="https://support.google.com/chrome/answer/95647?hl=pt-BR"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google Chrome
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href="https://support.mozilla.org/pt-BR/kb/ativar-e-desativar-cookies-que-os-sites-usam"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mozilla Firefox
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Safari
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Microsoft Edge
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href="https://help.opera.com/en/latest/web-preferences/#cookies"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Opera
                        </a>
                      </ListItem>
                    </List>
                    <p>
                      Lembre-se de que a desativação de cookies pode afetar a funcionalidade do
                      nosso site e sua experiência como usuário.
                    </p>

                    <h2>Alterações nesta Política de Cookies</h2>
                    <p>
                      Podemos atualizar esta política de cookies de tempos em tempos para refletir
                      mudanças em nossas práticas ou por outras razões operacionais, legais ou
                      regulamentares. Recomendamos que você reveja esta política periodicamente para
                      se manter informado sobre nosso uso de cookies.
                    </p>

                    <h2>Contato</h2>
                    <p>
                      Se você tiver alguma dúvida sobre nossa política de cookies, entre em contato
                      conosco através de:
                    </p>
                    <p>
                      <strong>Ilhas Business Software</strong>
                    </p>
                    <p>
                      Email: <a href="mailto:ibsoftcv@gmail.com">ibsoftcv@gmail.com</a>
                    </p>
                    <p>Telefone: +238 951 9944</p>

                    <p>
                      Esta política de cookies foi atualizada pela última vez em 09 de junho de
                      2024.
                    </p>
                  </TableCell>
                </TableRow>
              </TableContainer>
            </MKTypography>
          </Grid>
        </Container>
        <Divider pt={6} mt={6} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default GDPRPolicy;
