/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "ibsoftcv/Navbars/DefaultNavbar";
import DefaultFooter from "ibsoftcv/Footers/DefaultFooter";

// Routes
import data from "../../../../package.json";
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/telephone.jpg";

function ContactUs() {
  const [send, setSent] = useState(false);
  const qp = new URLSearchParams(location.search);
  const sent = Boolean(qp.get("ok") == "1");

  useEffect(() => {
    if (sent) {
      setSent(true);
      setTimeout(function () {
        setSent(false);
      }, 3000);
    }
  }, []);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" bgColor="white">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(80% + 30rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={0}
            mt={-2}
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor={!send ? "info" : "success"}
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                {!send ? "Conte conosco" : "Mensagem enviada!"}
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Para mais perguntas, incluindo oportunidades de parceria, por favor, envie um e-mail
                para {data.email} ou entre em contato usando nosso formulário de contato.
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autoComplete="off"
                action="/sendmsg.php"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Seu Nome / Empresa"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="E-mail"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="O que podemos ser útil?"
                      placeholder="Descreve o que pretende nos dizer com até 250 letras"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      required
                      name="msg"
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Enviar Mensagem
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
