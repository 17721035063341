/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "ibsoftcv/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={15}
              suffix="+"
              title="Sistemas Operativos"
              description="Trabalhamos com diversos sistemas operativos, garantindo que suas soluções sejam
                compatíveis e otimizadas, seja para Windows, macOS, Linux, ou plataformas móveis
                como iOS e Android..."
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={12}
              suffix="+"
              title="Linguagems de Computação"
              description="Full Stack Developer em diversas linguagems de computação, entre eles o Python, JavaScript, Java, C#, C++, Windev..."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={20}
              suffix="+"
              title="Freelancing"
              description="Com presença nas plataformas de alta competitividade como UpWork, Cisco DevNet e com
                mais de vinte anos de trajetória, garantimos criar soluções à sua medida."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
