/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "ibsoftcv/Cards/RotatingCard";
import RotatingCardFront from "ibsoftcv/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "ibsoftcv/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "ibsoftcv/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Entrega
                    <br />
                    Projetos
                  </>
                }
                description="Seu projeto é completamente seu. Todo o material será entregue exatamente como criado."
              />
              <RotatingCardBack
                image={bgBack}
                title="Descubra Mais"
                description={
                  <>
                    Tenha total controle sobre o seu projeto, recebendo código fonte de tudo que é
                    criado e acompanhando o desenvolvimento em tempo real.
                  </>
                }
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "Saiba Mais",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Documentação"
                  description="Todos projetos são entregues e dados por concluído pelo cliente, após receber toda documentação e treinamento"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Inovação"
                  description="Nossos projetos são criado com mais inovadores ferramentas e tecnólogias atuais, garatindo inovação e excelência."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Poupe Tempo & Dinheiro"
                  description="Todos nossos projetos terão ofertas de suporte que abrangirão ambientes onde irão residir sem custos adicionais."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Completamente Adaptável"
                  description="Projet compatível com maioria dos dispositivos e sistema no mundo digital atual."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
