/*
=========================================================
* Material Kit 2 React - v2<br/>1<br/>0
=========================================================

* Product Page: https://www<br/>creative-tim<br/>com/product/material-kit-react
* Copyright 2023 Ilhas Business Software (https://www<br/>creative-tim<br/>com)

Coded by www<br/>creative-tim<br/>com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software<br/>
*/

// Material Kit 2 React components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Material Kit 2 React examples
import DefaultNavbar from "ibsoftcv/Navbars/DefaultNavbar";
import DefaultFooter from "ibsoftcv/Footers/DefaultFooter";
import Breadcrumbs from "ibsoftcv/Breadcrumbs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function PrivacyPolicy() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(rgba(gradients.light.main, 0.8), rgba(gradients.dark.state, 0.8))}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Container>
          <Breadcrumbs
            xs={12}
            lg={9}
            justifyContent="center"
            mx="auto"
            routes={[
              { label: "Termos & condições", route: "/terms" },
              { label: "Política GDPR", route: "/cookies" },
              { label: "Confidencialidade", route: "/privacy" },
            ]}
          />
          <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mt={-35}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["14pt"],
                },
              })}
            >
              Termos e Serviços
            </MKTypography>
            <MKTypography
              variant="body1"
              color="lightgray"
              px={{ xs: 1, lg: 8 }}
              textAlign="justify"
              mt={1}
            >
              <MKTypography
                variant="h2"
                color="black"
                mt={3}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["14pt"],
                  },
                })}
              >
                Política de Privacidade
              </MKTypography>{" "}
              <TableContainer>
                <TableRow>
                  <TableCell>
                    Última atualização: 06 de maio de 2024
                    <br />
                    <br /> O seguinte Aviso de Privacidade foi criado para o www.ilhas.cv atual da
                    Ilhas Business Software e será revisado e atualizado periodicamente de acordo
                    com todas as leis e regulamentos aplicáveis.
                    <br />
                    <br />O objetivo deste Aviso de Privacidade é informá-lo facilmente sobre:{" "}
                    <br />
                    <List sx={{ listStyle: "number", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        {" "}
                        As definições dos termos fornecidos pelo GDPR
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        {" "}
                        Quem é a IBS e o que é www.ilhas.cv
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        {" "}
                        Onde você pode nos encontrar e como pode nos contatar
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Quais dados pessoais a Ilhas Business Software pode processar sobre você
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Como seus dados pessoais são processados pela Ilhas Business Software
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Quais são os seus direitos e como você pode exercê-los efetivamente
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Dados pessoais de crianças - você deve ter mais de 16 anos
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Quais precauções de segurança a Ilhas Business Software toma para protegê-lo
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>Links para outros sites</ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Alterações na política de privacidade
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Os propósitos e a base legal do processamento
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        A divulgação dos seus dados pessoais para terceiros
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Informações sobre a Autoridade de Supervisão de Proteção de Dados
                        Solicitando, Modificando ou Excluindo Seus Dados (GDPR)
                      </ListItem>
                    </List>
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableRow>
                  <TableCell colSpan={2}>
                    <h2>1. Definição de acordo com o GDPR</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ANSPDCP</TableCell>
                  <TableCell>
                    Representa a Autoridade Nacional de Supervisão da Proteção de Dados Pessoais
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dados pessoais</TableCell>
                  <TableCell>
                    Representa qualquer informação relativa a uma pessoa singular identificada ou
                    identificável (&apos;titular dos dados&apos;); uma pessoa singular identificável
                    é aquela que pode ser identificada, direta ou indiretamente, em particular por
                    referência a um identificador, como um nome, um número de identificação, dados
                    de localização, um identificador online ou a um ou mais fatores específicos da
                    identidade física, fisiológica, genética, mental, econômica, cultural ou social
                    dessa pessoa natural;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Processamento</TableCell>
                  <TableCell>
                    Representa qualquer operação ou conjunto de operações realizadas em dados
                    pessoais ou em conjuntos de dados pessoais, por meios automatizados ou não, como
                    coleta, registro, organização, estruturação, armazenamento, adaptação ou
                    alteração, recuperação, consulta, uso, divulgação por transmissão, disseminação
                    ou de outra forma disponibilização, alinhamento ou combinação, restrição,
                    apagamento ou destruição;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Restrição de processamento</TableCell>
                  <TableCell>
                    Representa a marcação de dados pessoais armazenados com o objetivo de limitar
                    seu processamento no futuro;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Controlador</TableCell>
                  <TableCell>
                    Representa a pessoa singular ou jurídica, autoridade pública, agência ou outros
                    corpos que, sozinhos ou em conjunto com outros, determinam os propósitos e os
                    meios do processamento de dados pessoais; onde os propósitos e os meios desse
                    processamento são determinados pela União Europeia ou pela lei de um Estado
                    Membro, o controlador ou os critérios específicos para sua nomeação podem ser
                    previstos pela lei da União ou do Estado Membro;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Processador</TableCell>
                  <TableCell>
                    Representa uma pessoa singular ou jurídica, autoridade pública, agência ou
                    outros corpos que processam dados pessoais em nome do controlador;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Destinatário</TableCell>
                  <TableCell>
                    Representa uma pessoa singular ou jurídica, autoridade pública, agência ou outro
                    corpo, a quem os dados pessoais são divulgados, seja uma terceira parte ou não.
                    No entanto, autoridades públicas que possam receber dados pessoais no âmbito de
                    uma inquérito particular de acordo com a União Europeia ou a lei de um Estado
                    Membro não devem ser consideradas como destinatários; o processamento desses
                    dados por essas autoridades públicas deve estar em conformidade com as regras de
                    proteção de dados aplicáveis de acordo com os propósitos do processamento;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Terceiros</TableCell>
                  <TableCell>
                    Uma pessoa singular ou jurídica, autoridade pública, agência ou corpo diferente
                    do titular dos dados, controlador, processador e pessoas que, sob a autoridade
                    direta do controlador ou do processador, estão autorizadas a processar dados
                    pessoais;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Consentimento do titular dos dados</TableCell>
                  <TableCell>
                    Representa qualquer indicação livre, específica, informada e inequívoca dos
                    desejos do titular dos dados pela qual ele ou ela, por uma declaração ou por uma
                    ação afirmativa clara, manifesta acordo com o processamento de dados pessoais
                    relativos a ele ou ela;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Violação de dados</TableCell>
                  <TableCell>
                    Representa uma violação de segurança que leva à destruição, perda, alteração,
                    divulgação não autorizada de, ou acesso a, dados pessoais. Isso significa que
                    uma violação é mais do que apenas perder dados pessoais.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Autoridade de Supervisão</TableCell>
                  <TableCell>
                    Uma autoridade pública independente que é estabelecida por um Estado Membro;
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer sx={{ gap: 2 }}>
                <TableRow>
                  <TableCell>
                    <h2>2. Quem é Ilhas Business Software e o que é www.ilhas.cv?</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>
                      &quot;Ilhas Business Software&quot; ou &quot;IBS&quot; é o nome comercial da
                      empresa ILHAS BUSINESS SOFTWARE, SOCIEDADE UNIPESSOAL, LDA, uma empresa de
                      responsabilidade limitada registrada em Cabo Verde sob o número único: NIF
                      284657204.
                    </p>
                    <p>
                      www.ilhas.cv é o site oficial e loja online da empresa que está continuamente
                      se desenvolvendo para se tornar sua primeira escolha de infraestrutura
                      dedicada para seus aplicativos em gerais incluindo web e móveis.
                    </p>
                    <p>
                      De acordo com o regulamento GDPR, nós (Ilhas Business Software) somos
                      considerados um controlador de dados em relação aos dados pessoais processados
                      através do nosso site www.ilhas.cv.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h2>3. Onde você pode nos encontrar e como pode nos contatar</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <List sx={{ listStyle: "disc", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        Nosso endereço de escritório registrado é Mindelo - Monte Sossego, Rua Vila
                        Nova, Cabo Verde.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Você pode nos contatar no seguinte e-mail: ibsoftcv@gmail.com
                      </ListItem>
                    </List>
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableRow>
                  <TableCell>
                    <h2>
                      4. Quais dados pessoais a Ilhas Business Software pode processar sobre você
                    </h2>
                    <p>
                      Para transparência, classificamos os dados pessoais em categorias de
                      processamento, para que você possa identificá-los facilmente. Os dados
                      pessoais coletados pela Ilhas Business Software podem incluir dados como:
                    </p>
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableHead>
                  <TableCell>Classe</TableCell>
                  <TableCell>Dados</TableCell>
                </TableHead>
                <TableRow>
                  <TableCell>Classe a.</TableCell>
                  <TableCell>
                    Dados essenciais (e-mail e nome de usuário), para a criação da sua conta de
                    cliente; dados opcionais (nome, sobrenome, foto e nome de usuário da sua rede
                    social) quando você decide personalizar sua conta
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe b.</TableCell>
                  <TableCell>E-mail, quando você nos fornece para fins de marketing;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe c.</TableCell>
                  <TableCell>
                    E-mail, nome completo e outras informações fornecidas por você quando você nos
                    contata usando o formulário disponível no nosso site na seção
                    &quot;Contate-nos&quot;;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe d.</TableCell>
                  <TableCell>
                    Informações técnicas padrão de login na internet que podem incluir: apenas uma
                    versão truncada do seu endereço IP, informações sobre o seu computador ou
                    dispositivo usado para acessar www.ilhas.cv (tipo de dispositivo, sistema
                    operacional, resolução de tela, idioma, país onde você está localizado, tipo de
                    navegador web, etc.); Seu IP é capturado e armazenado em formato anonimizado
                    suprimindo o último octeto, então seu endereço IP completo nunca chega aos
                    nossos servidores e nunca temos acesso a ele.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe e.</TableCell>
                  <TableCell>
                    Dados estatísticos agregados, como a cidade de onde seu tráfego está vindo,
                    demografia dos clientes ou interesses, seu comportamento que pode ser revelado
                    pela sua atividade no nosso site;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe f.</TableCell>
                  <TableCell>
                    A fonte do seu acesso quando você acessa nosso site através de um
                    site/aplicativo que está registrado em nosso programa de afiliados ou se você
                    acessou nosso site usando um cupom/voucher;
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classe g.</TableCell>
                  <TableCell>
                    Itens adicionados ao seu carrinho, suas credenciais de login, detalhes de
                    faturamento (como e-mail, nome completo, seu país e itens comprados) e seus
                    detalhes de pagamento, como os últimos dígitos do seu Cartão de Crédito, se você
                    selecionar este método de pagamento; Nunca coletamos seus detalhes de método de
                    pagamento em claro. Quando você escolhe um método de pagamento, você será
                    automaticamente redirecionado para a página web do provedor de pagamento
                    correspondente.
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableRow>
                  <TableCell>
                    <h2>5. Como seus dados pessoais são coletados pela Ilhas Business Software</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    <p>
                      Seus dados pessoais podem ser coletados pela Ilhas Business Software de duas
                      maneiras:
                    </p>
                    <List sx={{ listStyle: "number", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        Quando você fornece voluntariamente essas informações para nós preenchendo
                        os campos disponíveis - por exemplo, para criar sua conta, para nos
                        contatar, para se inscrever em nossa newsletter, e assim por diante. Com
                        base em nosso relacionamento contratual, podemos usar seu e-mail para enviar
                        informações relevantes sobre nossos serviços, ofertas e atualizações de
                        status de atividades. Em outros casos, seus dados pessoais serão usados para
                        fins de marketing apenas após obter seu consentimento prévio. Observe que
                        você pode optar por retirar seu consentimento a qualquer momento, sem afetar
                        o processamento já realizado, entrando em contato conosco pelo e-mail
                        ibsoftcv@gmail.com.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Podemos coletar automaticamente algumas informações técnicas sobre sua
                        atividade ou o dispositivo usado para acessar a Ilhas Business Software,
                        como uma versão truncada do seu endereço IP, tipo de sistema operacional,
                        tipo de navegador, resolução de tela, IPs suspeitos, ataques cibernéticos,
                        etc.
                      </ListItem>
                    </List>
                    <p>
                      Em geral, usamos cookies para coletar as informações mencionadas acima, que
                      são essenciais para a funcionalidade e segurança do nosso site ou para coletar
                      outros dados para fins de marketing.
                    </p>
                    <p>
                      Se você quiser saber mais sobre os cookies implementados em nosso site,
                      consulte nossa Política GDPR.
                    </p>
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableRow>
                  <TableCell colSpan={3}>
                    <h2>6. Objetivos e base legal do processamento</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    Todas as informações coletadas pela Ilhas Business Software de acordo com a
                    Seção 3 deste Aviso de Privacidade, são processadas para os seguintes
                    propósitos:
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Categoria</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Base Legal (GDPR)</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowspan="3">Armazenamento de Dados</TableCell>
                  <TableCell>
                    Todos os dados pessoais coletados são armazenados nos servidores de nossos
                    parceiros.
                  </TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Todas as informações fornecidas pelo usuário serão mantidas até que uma
                    solicitação de cancelamento seja feita.
                  </TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    As informações de faturamento serão mantidas de acordo com a regulamentação
                    aplicável.
                  </TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowspan="9">Finalidades e Base Legal do Processamento</TableCell>
                  <TableCell>
                    Processamos seus dados pessoais, como e-mail e nome de usuário, para criar sua
                    conta e personalizar sua experiência.
                  </TableCell>
                  <TableCell>Art. 6, parágrafo b) do GDPR - Desempenho Contratual</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Usamos o e-mail fornecido para enviar informações de marketing sobre nossos
                    serviços.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra a) do GDPR - Consentimento do Titular dos Dados
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Usamos informações fornecidas para oferecer suporte e responder a perguntas
                    sobre nossos produtos e serviços.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra b) do GDPR - Desempenho de um Contrato
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Processamos informações técnicas para facilitar o acesso ao nosso site e
                    garantir sua funcionalidade.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra f) do GDPR - Interesses Legítimos
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Coletamos dados para melhorar nossos serviços e para fins de marketing.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra a) do GDPR - Consentimento do Titular dos Dados
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Podemos coletar informações sobre a origem do seu acesso e recompensar
                    afiliados.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra a) do GDPR - Consentimento do Titular dos Dados
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Processamos seus dados pessoais para manter você conectado, lembrar produtos
                    adicionados ao carrinho de compras, gerar cupons, emitir faturas e receber
                    pagamentos.
                  </TableCell>
                  <TableCell>
                    Art. 6, parágrafo 1, letra b) do GDPR - Desempenho de um Contrato
                  </TableCell>
                </TableRow>
              </TableContainer>
              <TableContainer>
                <TableRow>
                  <TableCell colSpan={2}>
                    <h2>7. Qualquer divulgação dos seus dados pessoais a terceiros</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Nossos funcionários: Nossos funcionários têm acesso aos seus dados pessoais e
                    foram treinados para respeitar a confidencialidade.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Desenvolvimento de negócios: Não compartilharemos suas informações com terceiros
                    para fins de marketing direto.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Estamos tentando fazer o melhor em nossa indústria, então, às vezes, podemos
                    optar por colaborar com outras empresas para realizar certas funções
                    relacionadas aos negócios, como hospedagem ou recursos do Google Analytics.
                    Nesse caso, fornecemos a eles apenas as informações de que precisam para
                    realizar seu trabalho específico.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Por exemplo, podemos usar:
                    <List sx={{ listStyle: "number", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        Para hospedagem e armazenamento de dados: Amazon Web Services, Digital Ocean
                        e Heroku - se você quiser saber mais sobre a política de privacidade deles,
                        você pode encontrá-la{" "}
                        <a href="https://aws.amazon.com/privacy/?nc1=f_pr">AWS</a>,
                        <a href="https://www.digitalocean.com/legal/privacy-policy/">
                          Digital Ocean
                        </a>{" "}
                        e <a href="https://www.heroku.com/policy/security">Heroku</a>.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Para entrega de boletim eletrônico: MailChimp e SendGrid - se você quiser
                        saber mais sobre a política de privacidade deles, você pode encontrá-la{" "}
                        <a href="https://mailchimp.com/legal/privacy/">aqui</a>e{" "}
                        <a href="https://sendgrid.com/policies/privacy/services-privacy-policy/">
                          aqui
                        </a>
                        .
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Para monitorar a atividade do site: Google Analytics, Facebook Pixel, Hotjar
                        - se você quiser saber mais sobre a atividade deles, por favor, consulte
                        nossa Política de Cookies
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Para processar seu pagamento: 2checkout e Vinti4 - se você quiser saber mais
                        sobre a política de privacidade deles, você pode encontrá-la{" "}
                        <a href="https://www.2checkout.com/legal/">aqui</a> e{" "}
                        <a href="https://www.vinti4.cv/docs/Pol%C3%ADtica_de_Privacidade_Protecao_Dados_Site.pdf">
                          aqui
                        </a>
                        .
                      </ListItem>
                    </List>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Requisitos Legais: Seus dados pessoais podem ser comunicados às autoridades
                    governamentais e/ou agências de aplicação da lei, se exigido pela lei aplicável.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <h2>8. Quais são os seus direitos e como você pode exercê-los efetivamente?</h2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    A Ilhas Business Software, como controladora, garante medidas técnicas e
                    organizacionais para assegurar que seus direitos (como titular dos dados) sejam
                    respeitados:
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito de acesso</TableCell>
                  <TableCell>
                    Você tem o direito de obter a confirmação de que os dados pessoais que lhe dizem
                    respeito estão sendo processados por nós e, em caso afirmativo, acessar seus
                    dados pessoais e informações sobre como eles são processados.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito à portabilidade de dados</TableCell>
                  <TableCell>
                    Você tem o direito de receber alguns de seus dados pessoais, que nos forneceu,
                    em um formato estruturado, de uso comum e legível por máquina e também tem o
                    direito de transmitir esses dados para outro controlador sem impedimento da
                    nossa parte, quando tecnicamente viável.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito de objeção</TableCell>
                  <TableCell>
                    Você tem o direito de se opor ao processamento de seus dados pessoais quando o
                    processamento for necessário para a execução de uma tarefa realizada no
                    interesse público ou para os fins dos interesses legítimos perseguidos por nós.
                    Você tem o direito de se opor a qualquer momento se seus dados pessoais
                    estiverem sendo processados para fins de marketing direto.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito de retificação</TableCell>
                  <TableCell>
                    Você tem o direito de obter de nós, sem demora injustificada, a retificação de
                    dados pessoais inexatos que lhe digam respeito. A retificação será comunicada a
                    cada destinatário a quem os dados foram enviados, a menos que isso se prove
                    impossível ou envolva esforços desproporcionais (demonstráveis).
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito de apagamento (&apos;direito a ser esquecido&apos;)</TableCell>
                  <TableCell>
                    Você tem o direito de obter de nós o apagamento dos dados pessoais que lhe dizem
                    respeito, sem demora injustificada, e temos a obrigação de apagar seus dados
                    pessoais sem demora injustificada, onde um dos seguintes motivos se aplique:
                    seus dados pessoais não são mais necessários em relação aos fins para os quais
                    foram coletados ou processados de outra forma; você retira o consentimento no
                    qual o processamento se baseia e não há outro fundamento legal para o
                    processamento; você se opõe ao processamento e não existem motivos legítimos
                    para o processamento; seus dados pessoais foram processados ilegalmente; seus
                    dados pessoais devem ser apagados para cumprir uma obrigação legal; seus dados
                    pessoais foram coletados em relação à oferta de serviços da sociedade da
                    informação.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Direito à restrição do processamento</TableCell>
                  <TableCell>
                    Você tem o direito de obter de nós a restrição do processamento quando se aplica
                    uma das seguintes situações: você contesta a precisão de seus dados pessoais,
                    por um período que nos permita verificar a precisão de seus dados pessoais; o
                    processamento é ilegal e você se opõe ao apagamento de seus dados pessoais e
                    solicita a restrição de seu uso em vez disso; não precisamos mais de seus dados
                    pessoais para fins de processamento, mas eles são necessários para o
                    estabelecimento, exercício ou defesa de reivindicações legais; você se opôs ao
                    processamento, pendente da verificação se os motivos legítimos do controlador
                    prevalecem sobre os do titular dos dados.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <p>
                      Para qualquer informação ou solicitação de acordo com seus direitos, entre em
                      contato conosco através do seguinte endereço de e-mail: ibsoftcv@gmail.com
                    </p>

                    <h2>9. Dados pessoais de crianças</h2>
                    <p>
                      A Ilhas Business Software não coleta quaisquer Dados Pessoais de crianças
                      menores de 16 anos. Portanto, se você tem menos de 16 anos, por favor, não nos
                      envie nenhum Dado Pessoal.
                    </p>

                    <h2>
                      10. Quais precauções de segurança a Ilhas Business Software toma para
                      protegê-lo?
                    </h2>
                    <p>
                      Assumimos a responsabilidade de implementar medidas técnicas e organizacionais
                      adequadas em relação à proteção da privacidade e segurança de seus dados
                      pessoais. Tomamos todas as medidas razoáveis para proteger seus Dados Pessoais
                      contra danos, perda, uso indevido, acesso não autorizado, alteração,
                      destruição ou divulgação, conforme segue:
                    </p>
                    <List sx={{ listStyle: "number", pl: 4 }}>
                      <ListItem sx={{ display: "list-item" }}>
                        As pessoas que têm acesso ao nosso sistema de arquivamento são apenas
                        aquelas nomeadas pela Ilhas Business Software. Para acessar o sistema, elas
                        usam contas individuais e senhas que são alteradas periodicamente.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Todos os nossos funcionários, colaboradores e prestadores de serviços que
                        estão em contato com dados pessoais devem agir de acordo com os princípios e
                        políticas referentes ao processamento de dados pessoais. Eles foram
                        informados e assumiram respeitar o GDPR assinando os Acordos de
                        Processamento de Dados ou como efeito da lei.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Nossos funcionários e colaboradores acessam dados pessoais para o desempenho
                        de suas funções profissionais e apenas de acordo com o propósito declarado
                        da coleta de dados.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Os computadores a partir dos quais o sistema de arquivamento é acessado são
                        protegidos por senha e têm atualizações de segurança de antivírus, antispam
                        e firewall.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Os dados pessoais são impressos apenas por usuários autorizados, se
                        necessário, para realizar nossa atividade ou cumprir nossas obrigações
                        legais.
                      </ListItem>
                    </List>
                    <p>
                      Escolha cuidadosamente quais dados pessoais você decide enviar, lembrando que
                      a internet ou e-mails não são espaços impenetráveis, e um erro técnico pode
                      causar um evento infeliz a qualquer momento.
                    </p>

                    <h2>11. Links para outros sites</h2>
                    <p>
                      Em nosso site, você pode encontrar links para outras organizações. Este Aviso
                      de Privacidade não cobre os dados pessoais processados por elas.
                    </p>
                    <p>
                      Se você decidir acessar links de outras organizações, recomendamos que leia
                      atentamente seus Avisos de Privacidade, que devem estar disponíveis em seus
                      sites.
                    </p>

                    <h2>12. Alterações na política de privacidade</h2>
                    <p>
                      Por acreditar que estamos constantemente desenvolvendo nossos serviços,
                      estamos confiantes de que nossa plataforma em breve terá novas funções, por
                      isso, nosso Aviso de Privacidade será atualizado de acordo.
                    </p>
                    <p>
                      Para mantê-lo informado, sempre publicamos a versão mais recente do Aviso de
                      Privacidade em nosso site.
                    </p>
                    <p>
                      Asseguramos que a forma como coletamos e processamos seus dados pessoais está
                      de acordo com as disposições do Regulamento GDPR.
                    </p>
                    <p>
                      Se você tiver qualquer dúvida sobre nossa Política de Privacidade, entre em
                      contato conosco pelo ibsoftcv@gmail.com
                    </p>

                    <h2>13. Informações sobre a Autoridade de Supervisão de Proteção de Dados</h2>
                    <p>
                      Se você considerar que seus direitos previstos pelo Regulamento nº 679/2016
                      foram violados, pode nos contatar diretamente ou à nossa Autoridade de
                      Supervisão de Proteção de Dados: Comissão Nacional de Proteção de Dados
                      Pessoais (Comissão Nacional de Proteção de Dados) &quot;CNPD enviando uma
                      reclamação.
                    </p>
                    <p>Detalhes de contato da autoridade:</p>
                    <List>
                      <ListItem sx={{ display: "list-item" }}>
                        Link para reclamações/contato:{" "}
                        <a href="https://www.cnpd.cv/">https://www.cnpd.cv/</a>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Avenida China - CP 7600 +238 5340390 cnpd@cnpd.cv
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>Tel: +238 5340390</ListItem>
                    </List>

                    <h2>14. Solicitação, Modificação ou Exclusão de Seus Dados (GDPR)</h2>
                    <p>
                      Se você deseja solicitar uma modificação ou exportação de qualquer dado que
                      temos relacionado à sua conta, ou exclusão de todos os dados permanentemente,
                      por favor, envie-nos um e-mail para ibsoftcv@gmail.com e completaremos sua
                      solicitação de maneira oportuna.
                    </p>
                  </TableCell>
                </TableRow>
              </TableContainer>
            </MKTypography>
          </Grid>
        </Container>
        <Divider pt={6} mt={6} />
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
